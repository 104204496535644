import instagram from "../../assets/messengers/inst.png";
import whatsApp from "../../assets/messengers/whatsapp.png";
import telegram from "../../assets/messengers/telegram.png";
import viber from "../../assets/messengers/viber.png";

export const socialLinks = [
  {
    shortcut: "Instagram",
    href: "https://www.instagram.com/_autoport_/?igshid=1oa4f5a9wlv1g",
    img: instagram,
  },
  {
    shortcut: "WhatsApp",
    href: "https://wapp.click/79259790090",
    img: whatsApp,
  },
  {
    shortcut: "Telegram",
    href: "https://t.me/avtoportg",
    img: telegram,
  },
  {
    shortcut: "Viber",
    href: "https://msng.link/vi/79259790090",
    img: viber,
  },
];
