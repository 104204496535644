import React from "react";
import App from "./App";
import { CookiesProvider } from "react-cookie";
import { ThemeProvider } from "@material-ui/core/styles";
import { MainTheme } from "./styles/theme";
import ErrorState from "./context/errorBoundary/errorBoundary";
import Errors from "./context/errorBoundary/ErrorMsg/ErrorMsg";
import { render } from "react-dom";

  render(
    <React.StrictMode>
      <ErrorState>
        <CookiesProvider>
          <ThemeProvider theme={MainTheme}>
            <Errors />
            <App />
          </ThemeProvider>
        </CookiesProvider>
      </ErrorState>
    </React.StrictMode>,
    document.getElementById("root")
  );
