import React, { useContext } from 'react';
import ErrorContext from '../errorBoundaryContext';
import MuiAlert from "@material-ui/lab/Alert";
import  './ErrorsMsg.scss'

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
  }
const Errors = () => {
    const errorContext = useContext(ErrorContext);
    return (
        errorContext.alerts.length > 0 &&
        errorContext.alerts.map((error) => (
            <div key={error.id} className='error'>
                <Alert severity={error.type}> {error.msg}!</Alert>
            </div>
        ))
    );
};

export default Errors;
