import React, { useReducer } from 'react';
import AlertContext from './errorBoundaryContext';
import alertReducer from './errorBoundaryReducer';
import { v4 as uuidv4 } from 'uuid';
import { SET_ERROR, REMOVE_ERROR } from './errorTypes';


const ErrorState = (props) => {
    const initialState = [] ;
    const [state, dispatch] = useReducer(alertReducer, initialState);

    const setError = (msg, type, timeout ) => {
        const id = uuidv4();
        dispatch({
            type: SET_ERROR,
            payload: { msg, type, id },
        });

        setTimeout(() => dispatch({ type: REMOVE_ERROR, payload: id }), timeout);
    };

    return (
        <AlertContext.Provider
            value={{
                alerts: state,
                setError,
            }}
        >
            {props.children}
        </AlertContext.Provider>
    );
};

export default ErrorState;
