import React from "react";
import "./ServiceBlock.scss";
import { serviceData } from "./data";
import ServiceItem from "./ServiceItem/ServiceItem";

const ServiceBlock = () => {
  return (
    <div className='service-block'>
     {serviceData.map(item=>(
         <ServiceItem item={item} key={item.header}/>
     ))}
    </div>
  );
};
export default ServiceBlock;
