import React from 'react';
import './AboutBlock.scss'
import { aboutData } from './data';
import AboutItem from './AboutItem/AboutItem';

const AboutBlock =()=>{
    return(
        <div className='about-block'>
            {aboutData.map(item=>(
                <AboutItem item={item} key={item.header}/>
            ))}
        </div>
    )
}
export default AboutBlock;