import React, { useState } from "react";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";
import { useCookies } from "react-cookie";

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

export default function CookiesAlert() {
  const [cookie, setCookie] = useCookies(["modalCookie"]);
  const [open, setOpen] = useState(!cookie.modalCookie ? true : false);

  const handleClose = () => {
    setOpen(false);
    setCookie("modalCookie", true, {
      path: "/",
      expires: new Date(new Date().getTime() + 24 * 60 * 60 * 1000),
    });
  };

  return (
    <Snackbar open={open} autoHideDuration={10000} onClose={handleClose}>
      <Alert severity="info" onClose={handleClose}>
        Внимание! Пользуясь настоящим веб-сайтом, вы даете своё согласие на
        использование файлов cookies! Спасибо.
      </Alert>
    </Snackbar>
  );
}
