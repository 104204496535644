import pc from "../../../assets/about/pc.png";
import service from "../../../assets/about/service.png";
import delivery from "../../../assets/about/delivery.png";

export const aboutData = [
  {
    header: "Заказы ONLINE",
    description: "Заказывайте автозапчасти не выходя из дома",
    img: pc,
  },
  {
    header: "Сервис",
    description:
      "Устанавливайте автозапчасти у нас в сервисе ,быстро и качественно",
    img: service,
  },
  {
    header: "Доставка",
    description: "Доставка заказа осуществляется в течении рабочего дня",
    img: delivery,
  },
];
