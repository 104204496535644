import React from "react";
import './MainHeader.scss';
import logo from "../../assets/logo.png";
import { useHistory } from "react-router-dom";
import { socialLinks } from "./links";
import IconLink from "../MainPage/iconLinks/IconLink";

const MainHeader = () => {
  const history = useHistory();

  return (
    <div className='main-header'>
      <img src={logo} alt="main-logo" onClick={()=>history.push('/')}/>
      <div className='main-header__info'>
        <div href="tel:+79259790090">+7(925)979-00-90</div>
        <div>Autoparts1@mail.ru</div>
        <div className='main-header__info--social'>{socialLinks.map(item=>(
          <IconLink key={item.shortcut} img={item.img} title={item.shortcut} href={item.href}/>
        ))}</div>
      </div>
    </div>
  );
};
export default MainHeader;
