import React from "react";
import { BrowserRouter as Router } from "react-router-dom";
import Routes from "../src/router/router";
import MainHeader from "./components/MainHeader/MainHeader";
import "./styles/breakpoints.scss";
import "./styles/fonts.scss";
import { MainButton } from "./components/MainButton/MainButton";
import CookiesAlert from "./components/CookieAlert/CookieAlert";
import Footer from "./components/Footer/Footer";

const App = () => {
  return (
    <Router>
      <MainHeader />
      <MainButton />
      <Routes />
      <Footer/>
      <CookiesAlert />
    </Router>
  );
};

export default App;
