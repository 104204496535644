import React from "react";
import Modal from "@material-ui/core/Modal";
import Fade from "@material-ui/core/Fade";
import { makeStyles } from "@material-ui/core/styles";
import { Button } from "../../Button/Button";
import { useCookies } from "react-cookie";

const useStyles = makeStyles((theme) => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    borderRadius: "5px",
    border: "none",
    padding: theme.spacing(2, 4, 3),
    outline: "none",
  },
  buttonPannel: {
    paddingTop: "20px",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-evenly",
  },
}));

export default function TransitionsModal({ handleClose, open }) {
  const classes = useStyles();
  const [, setCookie] = useCookies(["order"]);

  const handleClick = () => {
    setCookie("order", [], { path: "/" });
    handleClose();
  };

  return (
    <div>
      <Modal className={classes.modal} open={open} onClose={handleClick}>
        <Fade in={open}>
          <div className={classes.paper}>
            <h2 style={{ textAlign: "center" }}>
              Спасибо!
              <br /> Ваш заказ оформлен,
              <br />в ближайшейшее время с Вами <br />
              свяжется менеджер <br />
              для уточнения деталей.
            </h2>

            <div className={classes.buttonPannel}>
              <Button
                text="ОК"
                onClick={() => handleClick()}
                style={{ maxWidth: "100px", maxHeight: "30px", padding: 0 }}
              />
            </div>
          </div>
        </Fade>
      </Modal>
    </div>
  );
}
