import React from "react";
import {
  YMaps,
  Map,
  GeoObject,
  TrafficControl,
  GeolocationControl,
} from "react-yandex-maps";

const mapState = {
  center: [55.877366, 38.42984],
  zoom: 17,
  controls: ["zoomControl", "fullscreenControl"],
};

export const YaMap = () => {
  return (
    <YMaps>
      <div style={{ width: "100%" }}>
        <Map
          width="100%"
          height="30vh"
          defaultState={mapState}
          modules={["control.ZoomControl", "control.FullscreenControl"]}
        >
          <TrafficControl options={{ float: "right" }} />
          <GeolocationControl options={{ float: "left" }} />

          <GeoObject
            geometry={{
              type: "Point",
              coordinates: [55.877366, 38.42984],
            }}
            properties={{
              // The placemark content.
              iconContent: "Автопорт",
            }}
            options={{
              // The placemark's icon will stretch to fit its contents.
              preset: "islands#blackStretchyIcon",
              // The placemark can be moved.
              draggable: true,
            }}
          />
        </Map>
      </div>
    </YMaps>
  );
};
