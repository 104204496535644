import React from "react";
import { Helmet } from "react-helmet";

const PageHelmet = ({ title, description, link }) => {
  return (
    <Helmet>
      <title>{title}</title>
      <meta name="description" content={description} />
      <link rel="canonical" href={`https:/avtoport.su${link}`} />
    </Helmet>
  );
};
export default PageHelmet;
