import React from "react";
import { Checkbox } from "@material-ui/core";

const OnlyComment = ({setHelp,help}) => {
  const handleChange = (event) => {
    setHelp(event.target.checked);
  };
  return (
    <div style={{paddingTop:'1em'}}>
      <Checkbox
        color="primary"
        onChange={handleChange}
        value={help}
      />Я не знаю номер детали, мне нужна консультация.
    </div>
  );
};
export default OnlyComment;
