import React from "react";
import "./Footer.scss";
import PlaceIcon from "@material-ui/icons/Place";

const Footer = () => {
  return (
    <div className="footer">
      <PlaceIcon />
      <div> г.Ногинск, ул. Декабристов, 79Б, (этаж 2)</div>
    </div>
  );
};
export default Footer;
