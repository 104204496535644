import { createMuiTheme } from "@material-ui/core";

export const MainTheme = createMuiTheme({
  overrides: {
    MuiInput: {
      underline: {
        "&:after": {
          borderBottom: `1px solid #444b5b`,
        },
        "&$focused:after": {
          borderBottomColor: `#444b5b`,
        },
        "&$error:after": {
          borderBottomColor: `#444b5b`,
        },
        "&:before": {
          borderBottom: `1px solid #444b5b`,
        },
        "&:hover:not($disabled):not($focused):not($error):before": {
          borderBottom: `1px solid #444b5b`,
        },
        "&$disabled:before": {
          borderBottom: `1px dotted #444b5b`,
        },
      },
    },
    MuiInputLabel: {
      root: {
        color: "#444b5b",
        "&$focused": {
          color: "#444b5b",
        },
      },
    },
  },
});
