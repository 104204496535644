import React from "react";
import "./CustomerCart.scss";
import { useCookies } from "react-cookie";
import CartItem from "./CartItem/CartItem";
import DeleteOutlineIcon from "@material-ui/icons/DeleteOutline";

const CustomerCart = ({help}) => {
  const [cookies, setCookie, removeCookie] = useCookies(["order"]);

  function updateCartItem(updatedData, article, param) {
    let updatedArray = [...cookies.order];
    param === 0
      ? updatedArray.splice(
          updatedArray.findIndex((item) => item.article === article),
          1
        )
      : (updatedArray.find((obj) => obj.article === article)[
          updatedData
        ] = param);
    setCookie("order", updatedArray, {
      path: "/",
      expires: new Date(new Date().getTime() + 3 * 24 * 60 * 60 * 1000),
    });
  }
  const cleanCart = () => {
    removeCookie("order");
  };

  return (
    <div>
      {!help && (
        <div className="customer-cart">
          {}{" "}
          <div className="customer-cart__header">
            Моя корзина{" "}
            {!cookies.order ||
              (cookies.order.length !== 0 && (
                <DeleteOutlineIcon
                  style={{ paddingLeft: "0.5em", cursor: "pointer" }}
                  titleAccess="Очистить корзину"
                  onClick={() => cleanCart()}
                />
              ))}
          </div>
          <div className="customer-cart__cart">
            {!cookies.order || cookies.order.length === 0 ? (
              <div className="customer-cart__empty">Корзина пуста</div>
            ) : (
              cookies.order.map((item) => (
                <CartItem
                  item={item}
                  key={item.class_cat}
                  setCart={updateCartItem}
                />
              ))
            )}
          </div>
        </div>
      )}
    </div>
  );
};
export default CustomerCart;
