import React from 'react';
import './AboutItem.scss'
const AboutItem =({item})=>{
    return(
        <div className='about-item'>
            <div className='about-item__header'>{item.header}</div>
            <div className='about-item__img' style={{backgroundImage:`url(${item.img})`}}></div>
            <div className='about-item__description'>{item.description}</div>
        </div>
    )
}
export default AboutItem;