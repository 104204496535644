import React from "react";
import "./Schedule.scss";

const Schedule = () => {
  return (
    <div className="schedule">
      <div>Режим работы:</div>
      <ul>
          <li>Понедельник-Суббота : 10:00 - 19:00</li>
      </ul>
    </div>
  );
};
export default Schedule;
