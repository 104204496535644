import React from "react";
import './Subtotal.scss'
import { useCookies } from "react-cookie";

const SubTotal = () => {
  const [cookies] = useCookies(["order"]);

  const total =
    !cookies.order || cookies.order.length < 1
      ? 0
      : cookies.order
          .map((item) => item.price * item.pcs)
          .reduce((a, b) => a + b);
  const pcs =
    !cookies.order || cookies.order.length < 1
      ? 0
      : Object.keys(cookies.order).length;
  return (
    <div>
      <div className='subtotal'>
        <b>Итого:</b> артикулов <span>{pcs}</span> на сумму <span>{total}</span> ₽
      </div>
    </div>
  );
};
export default SubTotal;
