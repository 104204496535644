import React from "react";
import "./CartItem.scss";
import AddCircleOutlineIcon from "@material-ui/icons/AddCircleOutline";
import RemoveCircleOutlineIcon from "@material-ui/icons/RemoveCircleOutline";
import DeleteForeverOutlinedIcon from "@material-ui/icons/DeleteForeverOutlined";
import { IconButton } from "@material-ui/core";
import noImage from "../../../../assets/noimg.png";

const pcs = "pcs";

const CartItem = ({ item, setCart }) => {
  return (
    <div className="item">
      <div className="cart-item">
        <img src={item.img ? item.img : noImage} alt={item.article} />
        <div className="cart-item__description">
          <div className="cart-item__description-header">{item.class_cat}</div>
          <div className="cart-item__description-article">
            Арт. <span>{item.article}</span>
          </div>
          <div className="cart-item__description-price">
            Цена/шт. <span>{item.price?item.price:'Н/Д'} ₽</span>
          </div>
        </div>
      </div>
      <div className="cart-item__buttons">
        <IconButton
          className="button-icon"
          onClick={() => setCart(pcs, item.article, item.pcs + 1)}
        >
          <AddCircleOutlineIcon />
        </IconButton>
        <div>{item.pcs}</div>
        {item.pcs !== 1 ? (
          <IconButton
            className="button-icon"
            onClick={() => setCart(pcs, item.article, item.pcs - 1)}
          >
            <RemoveCircleOutlineIcon />
          </IconButton>
        ) : (
          <IconButton
            className="button-icon"
            onClick={() => setCart(pcs, item.article, 0)}
          >
            <DeleteForeverOutlinedIcon color='error' />
          </IconButton>
        )}
      </div>
    </div>
  );
};
export default CartItem;
