import React from 'react';
import './IconLink.scss'

const IconLink = ({img,href,shortcut}) =>{
    return(
        <div className='icon-link' title={shortcut} onClick={()=>window.open(href)}>
            <img src ={img} alt={shortcut}/>
        </div>
    )
}
export default IconLink;