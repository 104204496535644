import React, { useEffect, useState, useCallback, useContext } from "react";
import useFetch from "../../../utils/apiFetch";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { TextField } from "@material-ui/core";
import { debounce } from "lodash";
import AlertContext from "../../../context/errorBoundary/errorBoundaryContext";

const ApiInput = ({ addToCart ,help}) => {
  const alertContext = useContext(AlertContext);
  const { setError } = alertContext;
  const [options, setOptions] = useState([]);
  const [input, setInputValue] = useState("");
  const [{ isLoading, response, error }, doFetch] = useFetch(
    `/GetSearchSuggestV3?login=&password=&search_text=${input}&code_region=1&row_count=50&type_request=string&api_key&`
  );

  useEffect(() => {
    if (!response) return;
    if (response.error !== "") setError(response.error, "error", 5000);
  }, [error, response]);

  const handler = useCallback(debounce(doFetch, 2500), []);

  const onChange = (event) => {
    setInputValue(event.target.value);
    handler();
  };

  useEffect(() => {
    if (!isLoading && response === null) return;
    setOptions(response.table);
  }, [response]);

  return (
    <Autocomplete
      id="asynchronous-demo"
      freeSolo={false}
      disabled={help}
      noOptionsText="Ничего не найдено"
      loadingText="Идёт поиск..."
      filterOptions={(x) => x}
      // onClose={()=>setOptions([])}
      getOptionSelected={(option, value) =>
        option.class_cat + option.partnumber + option.class_man
      }
      getOptionLabel={(option) =>
        option.class_cat + option.partnumber + option.class_man
      }
      options={options}
      loading={isLoading}
      renderInput={(params) => (
        <TextField
          {...params}
          label="Введите артикул или наименование"
          placeholder="Например: Колодки ауди а6 / или номер"
          onChange={onChange}
        />
      )}
      renderOption={(option) => (
        <div onClick={e => addToCart(option)} style={{width:'100%'}}>
          {option.class_cat}/{option.class_man}/{option.partnumber}
        </div>
      )}
    />
  );
};
export default ApiInput;
