import React from "react";
import "./ServiceItem.scss";

const ServiceItem = ({ item }) => {
  return (
    <div className="service-item">
      <div className="service-item__header">{item.header}</div>
      <div className="service-item__img" style={{backgroundImage:`url(${item.image}`}} alt={item.header} />
      <div className="service-item__description">{item.service}</div>
    </div>
  );
};
export default ServiceItem;
