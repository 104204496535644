import React from "react";
import { Switch, Route } from "react-router-dom";
import HomePage from "../pages/HomePage";
import OrderPage from "../pages/OrderPage";

const Routes = () => {

  return (
    <Switch>
      <Route path="/" exact component={HomePage} />
      <Route path="/order" exact component={OrderPage} />
    </Switch>
  );
};
export default Routes;
