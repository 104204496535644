import React, { useState, useEffect, useContext } from "react";
import "./OrderPage.scss";
import ApiInput from "../components/OrderPage/ApiInput/ApiInput";
import CustomerCart from "../components/OrderPage/CustomerCart/CustomerCart";
import { useCookies } from "react-cookie";
import CustomerForm from "../components/OrderPage/CustomerForm/CustomerForm";
import SubTotal from "../components/OrderPage/SubTotalBlock/Subtotal";
import useFetch from "../utils/apiFetch";
import AlertContext from "../context/errorBoundary/errorBoundaryContext";
import { CircularProgress } from "@material-ui/core";
import { ZzapLink } from "../components/ZzapLink/ZzapLink";
import OnlyComment from "../components/OrderPage/OnlyCommennt/OnlyComment";

const OrderPage = () => {
  const [cookies, setCookies] = useCookies(["order", "comment"]);
  const alertContext = useContext(AlertContext);
  const { setError } = alertContext;
  const [currentPart, setCurrentPart] = useState({
    article: "",
    class_man: "",
    class_cat: "",
    img: "",
    pcs: 1,
    price: 0,
  });
  const [help, setHelp] = useState(false);

  const [{ isLoading, response, error }, doFetch] = useFetch(
    `/GetSearchResultV3?login=&password=&search_text=&partnumber=${currentPart.article}&class_man=${currentPart.class_man}&code_region=1&row_count=20&type_request=1&`
  );
  const addToCart = (option) => {
    setCurrentPart({
      article: option.partnumber.replace(/\s/g, ""),
      class_man: option.class_man,
      class_cat: option.class_cat,
      img: option.imagepath,
      pcs: 1,
      price: 0,
    });
    doFetch();
  };

  useEffect(() => {
    if (!response) return;
    if (response.error !== "") setError(response.error, "error", 5000);
    if (error) setError(error, "error", 5000);
  }, [error, response]);

  function pushCustomerCart() {
    const findItem = cookies.order.find(
      (item) => item.article === currentPart.article
    );
    const newCart = cookies.order.map(function (item) {
      item.pcs = item.article === currentPart.article ? item.pcs + 1 : item.pcs;
      return item;
    });
    return findItem ? newCart : [...cookies.order, currentPart];
  }

  useEffect(() => {
    if (!response) return;
    const average = Math.round(
      (response.table.reduce((a, b) => (a = +a + b.priceV2), 0) * 1.2) /
        response.table.length
    );
    setCurrentPart((currentPart.price = average));

    setCookies("order", cookies.order ? pushCustomerCart() : [currentPart], {
      path: "/",
      expires: new Date(new Date().getTime() + 3 * 24 * 60 * 60 * 1000),
    });
  }, [response]);
  return (
    <div className="order-page">
      <ZzapLink />
      <ApiInput addToCart={addToCart} help={help}/>
      <OnlyComment setHelp={setHelp} help={help} />
      {isLoading ? (
        <div className="order-page__loading">
          <CircularProgress color="inherit" />
        </div>
      ) : (
        <CustomerCart help={help}/>
      )}
     {!help ?cookies.order !== undefined && cookies.order.length >= 1 && <SubTotal />:null}
      {help ? (
        <CustomerForm help={help}/>
      ) : (
        cookies.order !== undefined &&
        cookies.order.length >= 1 && <CustomerForm />
      )}
    </div>
  );
};
export default OrderPage;
