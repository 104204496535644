import React, { useState, useEffect, useContext } from "react";
import "./CustomerForm.scss";
import { Button } from "../../Button/Button";
import { TextField } from "@material-ui/core";
import NumberFormat from "react-number-format";
import useFetch from "../../../utils/mailFetch";
import { useCookies } from "react-cookie";
import AlertContext from "../../../context/errorBoundary/errorBoundaryContext";
import Alert from "@material-ui/lab/Alert";
import TransitionsModal from "../Modal/Modal";

const CustomerForm = ({ help }) => {
  const alertContext = useContext(AlertContext);
  const { setError } = alertContext;
  const [{ isLoading, error }, doFetch] = useFetch();
  const [cookies] = useCookies(["order"]);
  const order = cookies.order ? cookies.order : [{}];
  const [customer, setCustomer] = useState({
    name: "",
    phone: "",
    comment: "",
    vin: "",
  });
  const [open, setOpen] = useState(false);

  const handleOpen = () => {
    if (!error) setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const onInputChange = (value, inputName) => {
    setCustomer({ ...customer, [inputName]: value });
  };
  useEffect(() => {
    if (error)
      setError("Произошла ошибка, повторите через 2 минуты", "error", 7000);
  }, [error]);

  const validation = () => {
    if (
      customer.name.length >= 2 &&
      customer.phone.replace(/\s/g, "").length === 16
    )
      return false;
    return true;
  };
  const handleSubmitOrder = (event) => {
    event.preventDefault();
    doFetch({
      method: "post",
      data: { customer, order: order },
    });
    handleOpen();
  };

  return (
    <div className="customer-form">
      <form>
        <TextField
          onChange={(e) => onInputChange(e.target.value, "name")}
          value={customer.name}
          type="text"
          label="ФИО"
          fullWidth
          style={{ margin: "0.5em 0" }}
          required
        />
        {help ? (
          <TextField
            onChange={(e) => onInputChange(e.target.value, "vin")}
            value={customer.vin}
            error={customer.vin.length !== 17 ? true : false}
            placeholder="17 символов"
            type="text"
            label="VIN номер"
            fullWidth
            style={{ margin: "0.5em 0" }}
            required
            style={{ color: customer.vin.length !== 17 ? "red" : "black" }}
          />
        ) : null}
        <NumberFormat
          value={customer.phone}
          onChange={(e) => onInputChange(e.target.value, "phone")}
          customInput={TextField}
          className="form-input"
          format="+7(###)###-##-##"
          label="Телефон"
          fullWidth
          style={{ margin: "0.5em 0" }}
          placeholder="(###)###-##-##"
          required
        />
        <TextField
          onChange={(e) => onInputChange(e.target.value, "comment")}
          value={customer.comment}
          type="text"
          label={help ? "Какую деталь вы ищете?" : "Комментарий"}
          fullWidth
          multiline
          rows={2}
          style={{ margin: "0.5em 0" }}
        />

        <Alert severity="warning" style={{ margin: "1em 0" }}>
          Внимание! Итоговая цена будет озвучена после обратного звонка
        </Alert>
        <Button
          text="Оформить заказ"
          type="submit"
          disabled={validation() || isLoading}
          onClick={handleSubmitOrder}
        />
      </form>
      <TransitionsModal handleClose={handleClose} open={open} />
    </div>
  );
};
export default CustomerForm;
