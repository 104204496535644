import React from "react";
import { useHistory } from "react-router-dom";
import { Button } from "../Button/Button";
import "./MainButton.scss";
import SearchIcon from "@material-ui/icons/Search";
import HomeOutlinedIcon from "@material-ui/icons/HomeOutlined";

export const MainButton = () => {
  const history = useHistory();
  const path = history.location.pathname;
  const label = path === "/" ? "Поиск деталей" : "Главная";
  const handleClick =
    path === "/" ? () => history.push("/order") : () => history.push("/");
  return (
    <Button text={label} className="main-button" onClick={handleClick}>
      {path === "/" ? <SearchIcon /> : <HomeOutlinedIcon />}
    </Button>
  );
};
