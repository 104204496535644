import React from "react";
import "./HomePage.scss";
import ServiceBlock from "../components/MainPage/ServiceBlock/ServiceBlock";
import AboutBlock from "../components/MainPage/AboutBlock/AboutBlock";
import { YaMap } from "../components/MainPage/YaMap/YaMap";
import { YMInitializer } from "react-yandex-metrika";
import PageHelmet from "../components/Helmet/PageHelmet";
import Schedule from "../components/MainPage/Schedule/Schedule";

const HomePage = () => {
  return (
    <div className="home-page">
      <PageHelmet
        title="Автопорт Ногинск. Любая деталь для вашего авто, доставка в течении рабочего дня."
        description="Магазин автозапчастей для иномарок, быстрые сроки доставки , возможность заказать из дома, сервис , Online  оплата"
        link="/"
      />
      <YMInitializer accounts={[53943304]} options={{ webvisor: true }} />
      <h1>Любая деталь для вашего авто</h1>
      <div className="home-page__description">
        У нас индивидуальный подход к каждому клиенту, предоставляя
        многочисленные, а самое главное, качественные услуги.
      </div>
      <AboutBlock />
      <ServiceBlock />
      <Schedule/>
      <YaMap />
    </div>
  );
};
export default HomePage;
