import React from "react";
import './style.scss';

export const Button = ({
  type = "default",
  className = "",
  text = "",
  onClick,
  style,
  children,
  ...rest
 
}) => {
  return (
    <div className={`button ${type} ${className}`} style={style}>
      <button onClick={onClick} {...rest} style={style}>
        <span className={"text-wrapper"}>{text}</span>
        {children}
      </button>
    </div>
  );
};
