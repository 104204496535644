import React from "react";
import diag from "../../../assets/service/diag.png";
import eng from "../../../assets/service/eng.png";
import hod from "../../../assets/service//hod.jpg";
import mkpp from "../../../assets/service/mkpp.jpg";
import rul from "../../../assets/service/rul.jpg";
import supp from "../../../assets/service/supp.jpg";
export const serviceData = [
  {
    header: "Услуги",
    service: (
      <ul>
        <li>Компьютерная диагностика автомобилей</li>
        <li>Заправка кондиционеров</li>
        <li>Развал-схождение</li>
      </ul>
    ),
    image: diag,
  },
  {
    header: "Ремонт двигателя",
    service: (
      <ul>
        <li>Замена ГРМ(цепи, ремни)</li>
        <li>Ремонт ГБЦ</li>
        <li>Ремонт блока двигателя</li>
        <li>Ремонт турбокомпрессоров</li>
      </ul>
    ),
    image: eng,
  },
  {
    header: "Ремонт тормозной системы",
    service: (
      <ul>
        <li>Замена тормозных колодок</li>
        <li>Замена тормозных дисков</li>
        <li>Замена тормозных шлангов</li>
        <li>Замена тормозной жидкости</li>
        <li>Ремонт тормозных суппортов</li>
      </ul>
    ),
    image: supp,
  },
  {
    header: "Ремонт ходовой части",
    service: (
      <ul>
        <li>Замена шаровых опор</li>
        <li>Замена стоек стабилизаторов</li>
        <li>Замена рычагов подвески</li>
        <li>Замена амортизаторов</li>
        <li>Замена сайлентблоков</li>
      </ul>
    ),
    image: hod,
  },
  {
    header: "Ремонт рулевого управления",
    service: (
      <ul>
        <li>Замена рулевых наконечников</li>
        <li>Замена рулевых тяг</li>
        <li>Замена жидкости ГУР</li>
        <li>Ремонт рулевой рейки(+ гарантия 6 мес.)</li>
      </ul>
    ),
    image: rul,
  },
  {
    header: "Ремонт Трансмиссии",
    service: (
      <ul>
        <li>Замена масла АКПП/МКПП</li>
        <li>Замена ШРУС</li>
        <li>Ремонт МКПП</li>
        <li>Ремонт карданного вала</li>
      </ul>
    ),
    image: mkpp,
  },
];
