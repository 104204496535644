import { useState, useEffect } from "react";
import axios from "axios";
import parser from 'fast-xml-parser'

export default (url) => {
  const _apiKey = 'MBmE7rdJlQjqwrBIeYkM8uY3fVhWpM4DsUvXzvyQhmEK2pMR4THzUsXOiOj'
  const baseUrl = `https://www.zzap.ru//webservice/datasharing.asmx${url}api_key=${_apiKey}`;
  const [isLoading, setIsLoading] = useState(false);
  const [response, setResponse] = useState(null);
  const [error, setError] = useState(null);
  const [options, setOptions] = useState({});
  const doFetch = (options = {}) => {
    setOptions(options);
    setIsLoading(true);
  };

  useEffect(() => {
    if (!isLoading) {
      return;
    }

    axios(baseUrl, options)
      .then((res) => {
        setResponse(JSON.parse(parser.parse(res.data).string));
        setIsLoading(false);
      })
      .catch((error) => {
        setError(error.response);
        setIsLoading(false);
      });
  }, [isLoading, options, url]);

  return [{ isLoading, response, error }, doFetch];
};
